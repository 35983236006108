<template>
  <div class="pa-4">
    <h1 class="text-h3">Aset GKBI</h1>
    <p>
      Statistik GKBI tahun 2021/2022 apabila diukur dari setoran masing-masing jemaat ke sinode
    </p>
    <v-data-table
      v-if="$vuetify.breakpoint.mdAndUp"
      :headers="headers"
      :items="tableRows"
      dense
      disable-sort
    >
      <template #[`group.header`]="{ group, toggle, headers, isOpen }">
        <th class="text-h6" @click="toggle" style="cursor: pointer" :colspan="headers.length">
          <v-icon left>{{ isOpen ? 'mdi-chevron-up' : 'mdi-chevron-down'}}</v-icon>
          <span>Tahun {{ group }}</span>
        </th>
      </template>
    </v-data-table>
    <v-expansion-panels v-else multiple>
      <v-expansion-panel v-for="mH in mobHeaders" :key="mH.text">
        <v-expansion-panel-header>{{ mH.text }}</v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-simple-table>
            <tr>
              <th style="padding-bottom: 5px; border-bottom: 1px solid rgba(0, 0, 0, 0.15); width: 25%">Tahun</th>
              <th style="padding-bottom: 5px; border-bottom: 1px solid rgba(0, 0, 0, 0.15)">Jumlah</th>
            </tr>
            <tr
              v-for="row in tableRows"
              :key="row.keterangan"
            >
              <td
                class="text-center"
                style="border-bottom: 1px solid rgba(0, 0, 0, 0.15); padding: 3px 0px"
              >
                {{row.keterangan}}
              </td>
              <td
                class="text-center"
                style="border-bottom: 1px solid rgba(0, 0, 0, 0.15); padding: 3px 0px"
              >{{row[mH.value]}}</td>
            </tr>
          </v-simple-table>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </div>
</template>

<script>
import { defineComponent } from '@vue/composition-api'

export default defineComponent({
  name: 'AsetTahunan',
  setup () {
    const headers = [
      {
        text: 'Tahun',
        value: 'keterangan'
      },
      {
        text: 'Sertifikat',
        value: 'sertifikat'
      },
      {
        text: 'Hibah +',
        value: 'hibah'
      },
      {
        text: 'Lain-lain',
        value: 'lain'
      },
      {
        text: 'Jemaat',
        value: 'jemaat'
      },
      {
        text: 'Beton',
        value: 'beton'
      },
      {
        text: 'Kayu',
        value: 'kayu'
      },
      {
        text: 'Rumah',
        value: 'rumah'
      },
      {
        text: 'Jiwa',
        value: 'jiwa'
      },
      {
        text: 'Gembala',
        value: 'gbl'
      },
      {
        text: 'Pembantu Gembala',
        value: 'pem'
      },
      {
        text: 'Vikaris',
        value: 'vic'
      }
    ]

    const tableRows = [
      {
        sertifikat: null,
        hibah: null,
        lain: null,
        keterangan: 2019,
        jemaat: 41,
        beton: 28,
        kayu: 5,
        rumah: 7,
        jiwa: 2872,
        gbl: 42,
        pem: 8,
        vic: 3
      },
      {
        sertifikat: null,
        hibah: null,
        lain: null,
        keterangan: 2020,
        jemaat: 42,
        beton: 28,
        kayu: 5,
        rumah: 7,
        jiwa: 2887,
        gbl: 41,
        pem: 7,
        vic: 2
      },
      {
        sertifikat: 11,
        hibah: 11,
        lain: 20,
        keterangan: 2021,
        jemaat: 43,
        beton: 28,
        kayu: 5,
        rumah: 7,
        jiwa: 3016,
        gbl: 39,
        pem: 7,
        vic: 6
      },
      {
        sertifikat: 12,
        hibah: 16,
        lain: 20,
        keterangan: 2022,
        jemaat: 44,
        beton: 28,
        kayu: 5,
        rumah: 8,
        jiwa: 3034,
        gbl: 41,
        pem: 11,
        vic: 7
      }
    ]

    const mobHeaders = headers.filter(header => header.text !== 'Tahun')

    return { headers, tableRows, mobHeaders }
  }
})
</script>
